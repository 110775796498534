@font-face {
  font-family: "IBM Plex Sans";
  src: url("../assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../assets/fonts/IBMPlexSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../assets/fonts/IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../assets/fonts/IBMPlexSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../assets/fonts/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../assets/fonts/IBMPlexSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
